<template>
    <div style="background: #202020;">
        <div>
            <headerComponent></headerComponent>
        </div>

        <div class="versegrid">
            <div class="verseHeader">
                <img src="./../assets/images/verse/verse.png">
            </div>
            <div class="web3">
                <h2>We enter the world of Web3 with the exclusive premium TLD .verse</h2>
            </div>
            <div class="versegif">
                <img class="versegifnew" src="./../assets/images/verse/verse.gif">
            </div>
            <div class="versetext">
                <h4>Explore our <b>exclusive selection</b> of SLDs and seize the opportunity to be part of the <b>digital evolution</b> with us!</h4>
            </div>
        </div>

        <div class="versedomainsgrid">

            <div  class="versedomains">
                <div v-for="(domain,index) in displayedItems" :key="index" class="versedomainsbox">
                    <div class="versedomainsboxtext">
                        <h5>{{domain.parameters}}</h5>
                    </div>
                    <div class="versedomainsboxbtn">
<!--                        <a :href="`https://monoverse.com/checkout?`+domain.parameters.toLowerCase()" >-->
                            <div class="versedomainsboxbtntxt">Buy Now</div>
<!--                        </a>-->
                    </div>
                </div>

            </div>
            <div class="pagination" >
                <button class="web3pagination" @click="previousPage" :disabled="currentPage === 1" aria-label="left">
                    <div class="web3paginationtext">Previous {{itemsPerPage}}</div><img src="./../assets/images/verse/rightbtn.png">
                </button>

                <button class="web3pagination" @click="nextPage" :disabled="currentPage === totalPages" aria-label="right">
                    <img src="./../assets/images/verse/leftbtn.png"><div class="web3paginationtext">Next {{itemsPerPage}}</div>
                </button>
            </div>
        </div>

        <div>
            <verseForm style="background-color: #141414;"></verseForm>
        </div>
        <div style="background: linear-gradient(226deg, #03DAC6 1.65%, #5091E6 46.75%, #812CC8 96.85%);">
            <div class="commingsoon">
                <h1>Coming soon </h1>
                <h3 style="text-align: center">in Monoverse</h3>
            </div>
            <div class="commingsoongrid">
                <div class="commingsoongridcolumn1">
                    <img class="commingsoongridcolumn1image" src="./../assets/images/verse/builder.png">
                    <h3>Website Builder</h3>
                    <h4>Monoverse is developing a Website Builder exclusively for web3 .verse domains.</h4>
                </div>
                <div class="commingsoongridcolumn1">
                    <img class="commingsoongridcolumn1image" src="./../assets/images/verse/chat.png">
                    <h3>Chat with web3</h3>
                    <h4>Monoversians will be able to chat using XMTP technology.</h4>
                </div>
                <div class="commingsoongridcolumn1">
                    <img  class="commingsoongridcolumn1image" src="./../assets/images/verse/brand.png">
                    <h3>Brand Identity</h3>
                    <h4>Establish your web3 web space with Monoverse Branding.</h4>
                </div>
            </div>


        </div>


        <div>
            <mainfooter></mainfooter>
        </div>
    </div>
</template>

<script>
    import headerComponent from "@/components/reusable/header";
    import mainfooter from "@/components/footer";
    import verseForm from "@/components/reusable/verseForm.vue";

    export default {
        name: "Verse",
        components: {headerComponent,mainfooter,verseForm},
        data: () => ({
            itemsPerPage: 36,
            visiblePageCount: 7,
            currentPage: 1,
            w3domains: [{parameters:"ai.verse"},
                {parameters:"alpha.verse"},
                {parameters:"app.verse"},
                {parameters:"art.verse"},
                {parameters:"auto.verse"},
                {parameters:"beauty.verse"},
                {parameters:"best.verse"},
                {parameters:"bio.verse"},
                {parameters:"bitcoin.verse"},
                {parameters:"block.verse"},
                {parameters:"blockchain.verse"},
                {parameters:"blog.verse"},
                {parameters:"bet.verse"},
                {parameters:"book.verse"},
                {parameters:"brand.verse"},
                {parameters:"btc.verse"},
                {parameters:"business.verse"},
                {parameters:"cannabis.verse"},
                {parameters:"car.verse"},
                {parameters:"casino.verse"},
                {parameters:"cbd.verse"},
                {parameters:"chat.verse"},
                {parameters:"chain.verse"},
                {parameters:"coffee.verse"},
                {parameters:"coin.verse"},
                {parameters:"connect.verse"},
                {parameters:"crypto.verse"},
                {parameters:"cyber.verse"},
                {parameters:"dao.verse"},
                {parameters:"data.verse"},
                {parameters:"defi.verse"},
                {parameters:"design.verse"},
                {parameters:"dev.verse"},
                {parameters:"digital.verse"},
                {parameters:"drone.verse"},
                {parameters:"energy.verse"},
                {parameters:"eth.verse"},
                {parameters:"ethereum.verse"},
                {parameters:"future.verse"},
                {parameters:"fashion.verse"},
                {parameters:"game.verse"},
                {parameters:"geo.verse"},
                {parameters:"green.verse"},
                {parameters:"hone.verse"},
                {parameters:"health.verse"},
                {parameters:"hotel.verse"},
                {parameters:"info.verse"},
                {parameters:"insurance.verse"},
                {parameters:"internet.verse"},
                {parameters:"invest.verse"},
                {parameters:"job.verse"},
                {parameters:"lab.verse"},
                {parameters:"law.verse"},
                {parameters:"life.verse"},
                {parameters:"link.verse"},
                {parameters:"live.verse"},
                {parameters:"love.verse"},
                {parameters:"mail.verse"},
                {parameters:"market.verse"},
                {parameters:"media.verse"},
                {parameters:"music.verse"},
                {parameters:"nft.verse"},
                {parameters:"news.verse"},
                {parameters:"nfts.verse"},
                {parameters:"nomad.verse"},
                {parameters:"nova.verse"},
                {parameters:"online.verse"},
                {parameters:"open.verse"},
                {parameters:"play.verse"},
                {parameters:"pay.verse"},
                {parameters:"porn.verse"},
                {parameters:"radio.verse"},
                {parameters:"real.verse"},
                {parameters:"realestate.verse"},
                {parameters:"robot.verse"},
                {parameters:"security.verse"},
                {parameters:"seo.verse"},
                {parameters:"sex.verse"},
                {parameters:"shop.verse"},
                {parameters:"sky.verse"},
                {parameters:"smart.verse"},
                {parameters:"social.verse"},
                {parameters:"solar.verse"},
                {parameters:"space.verse"},
                {parameters:"sport.verse"},
                {parameters:"star.verse"},
                {parameters:"startup.verse"},
                {parameters:"store.verse"},
                {parameters:"tech.verse"},
                {parameters:"token.verse"},
                {parameters:"travel.verse"},
                {parameters:"uni.verse"},
                {parameters:"vegan.verse"},
                {parameters:"video.verse"},
                {parameters:"vip.verse"},
                {parameters:"virtual.verse"},
                {parameters:"wallet.verse"},
                {parameters:"web3.verse"},
                {parameters:"work.verse"},
                {parameters:"yoga.verse"},
                {parameters:"web.verse"}],
            show:false,
        }),

        methods:{
            previousPage() {
                if (this.currentPage > 1) {
                    this.currentPage--;
                }
            },
            nextPage() {
                if (this.currentPage < this.totalPages) {
                    this.currentPage++;
                }
            },
            goToPage(pageNumber) {
                if (pageNumber >= 1 && pageNumber <= this.totalPages) {
                    this.currentPage = pageNumber;
                }
            },
            handleWindowResize() {
                // This method will be called whenever the window is resized
                var windowWidth = window.innerWidth;
                if (windowWidth > 1264){
                    this.itemsPerPage=36;
                }
                else if (windowWidth > 960){
                    this.itemsPerPage=27;
                }
                else if (windowWidth > 600){
                    this.itemsPerPage=16;
                }
                else{
                    this.itemsPerPage=8;
                }
                // console.log(windowWidth);
            }
        },

        computed: {
            displayedItems() {
                const startIndex = (this.currentPage - 1) * this.itemsPerPage;
                const endIndex = startIndex + this.itemsPerPage;
                // console.log(this.$store.getters.domainInfo.slice(startIndex, endIndex))
                return this.w3domains.slice(startIndex, endIndex);

            },
            totalPages() {
                return Math.ceil(this.w3domains.length / this.itemsPerPage);
            },
            visiblePageNumbers() {
                const middlePage = Math.ceil(this.visiblePageCount / 2);
                const startPage = Math.max(this.currentPage - middlePage, 1);
                const endPage = Math.min(startPage + this.visiblePageCount - 1, this.totalPages);

                const pageNumbers = [];
                for (let i = startPage; i <= endPage; i++) {
                    pageNumbers.push(i);
                }
                return pageNumbers;
            },


        },
        mounted() {
            this.show = true; // might need this.$nextTick
            window.addEventListener('resize', this.handleWindowResize);
            window.addEventListener('load', this.handleWindowResize);

        },
        beforeDestroy() {
            // Remove the event listener when the component is destroyed
            window.removeEventListener('resize', this.handleWindowResize);
            window.removeEventListener('load', this.handleWindowResize);
        },

    }
</script>

<style>

    .pagination {

        display: flex;
        justify-content: center;
        margin-top: 0px;
        margin-bottom: 0px;
        height: 100px;
    }

    .pagination button {
        /*background-color: #eaeaea;*/
        border: none;
        color: #333;
        padding: 0px 0px;
        margin: 0 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    .pagination button:hover {
        background-color: #ffffff00;
    }

    .pagination button:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }

    .pagination button:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    .pagination button:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .pagination .page-numbers {
        display: flex;
        align-items: center;
    }

    .pagination .page-numbers button {
        background-color: transparent;
        border: none;
        color: #333;
        padding: 8px;
        cursor: pointer;
    }

    .pagination .page-numbers button.active {
        font-weight: bold;
        text-decoration: underline;
        color: #A5A5A5;
    }
</style>


