<template>

    <div id="app" style="padding-bottom: 96px ">
        <div class="versesupportHeader"><h2 style="text-align: center">Pick your favourite .verse domain name and we’ll notify you when it’s available for purchase.</h2></div>
<!--        <div class="supportSubHeader"><p class="supportSubHeaderP" style="text-align: center">If you can’t find your answer to the above <u>FAQ</u> please use the-->
<!--            following form to describe the issue and we’ll be in touch soon.</p></div>-->
        <form class="vue-form" @submit.prevent="sendEmail()" style="background-color: #141414">

            <div class="error-message">
                <p v-show="!email.valid">Please enter a valid email address.</p>
            </div>

            <fieldset>

                <div>
                    <input type="email" name="sld" id="sld" required=""
                           v-model="sld"
                           placeholder="Your favourite SLD">
                </div>

                <div>
                    <input type="text" name="name" id="name" required=""
                           v-model="industry"
                           placeholder="Your industry">
                </div>
                <div>
                    <input type="email" name="email" id="email" required=""
                           :class="{ email , error: !email.valid }"
                           v-model="email.value"
                           placeholder="Your email address">
                </div>


                <div class="verseterm"><p class="termsp">Kindly read <u>Monoverse’s Terms of Use.</u><br>
                    To continue, you’ll need to accept the Terms of Use by checking the box.</p></div>
                <div>
                    <input type="checkbox" v-model="checkOne">
                    <label>I accept Monoverse’s terms of use</label>
                </div>
                <div>
<!--                    <v-icon icon="md:info"></v-icon>-->
                    <input :disabled="!checkOne || !email.valid || email.value==='' || selection.features==='' || name==='' || !email.valid" type="submit" onclick = "sendEmail()" value="Send Email">
                </div>
            </fieldset>


        </form>

<!--        <div class="debug">-->
<!--            <pre><code>{{ $data }}</code></pre>-->
<!--        </div>-->

    </div>
</template>


<script >
    // import { Email } from "@/assets/smtp/smtp.js"
    // import { Email.send }  from "@/assets/smtp/smtp.js"
    import request from "@/requests";

    export default {
        name: "newForm",
        data: function() {
            return {
                name: "",
                sld:"",
                industry:"",
                checkOne:0,
                email: {
                    value: "",
                    valid: true
                },
                features: ["Interested in Custom Services (branding, hosting, corporate, legal etc)", "Eager to Negotiate Price", "Wish to apply for Domain Parking"],
                selection: {
                    member: "0",
                    framework: "vue",
                    features: []
                },
                message: {
                    text: "",
                    maxlength: 255
                },
                submitted: false
            };

        },

        methods: {

            sendEmail(){
                // console.log(1);


                let obj = {};



                obj.customerEmail = this.email.value;
                obj.body = this.sld;
                obj.subject = this.industry
                obj.name = this.name;
                // console.log(obj)

                request.postRequest('', 'contact/customer-email', obj,this.postRequestCallback);


            },

            postRequestCallback(response){
                // console.log(response)
                document.location.href="/email-received";
            },

            // submit form handler
            submit: function() {
                this.submitted = true;
            },
            // validate by type and value
            validate: function(type, value) {
                if (type === "email") {
                    this.email.valid = this.isEmail(value) ? true : false;
                }
            },
            // check for valid email adress
            isEmail: function(value) {
                return emailRegExp.test(value);
            },
            // check or uncheck all
            checkAll: function(event) {
                this.selection.features = event.target.checked ? this.features : [];
            }
        },
        watch: {
            // watching nested property
            "email.value": function(value) {
                this.validate("email", value);
            }
        }
    };


    var emailRegExp = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;


    /* SmtpJS.com - v3.0.0 */
    var Email = {
        send: function (a) {
            return new Promise(function (n, e) {
                a.nocache = Math.floor(1e6 * Math.random() + 1), a.Action = "Send";
                var t = JSON.stringify(a);
                Email.ajaxPost("https://smtpjs.com/v3/smtpjs.aspx?", t, function (e) {
                    n(e)
                })
            })
        }, ajaxPost: function (e, n, t) {
            var a = Email.createCORSRequest("POST", e);
            a.setRequestHeader("Content-type", "application/x-www-form-urlencoded"), a.onload = function () {
                var e = a.responseText;
                null != t && t(e)
            }, a.send(n)
        }, ajax: function (e, n) {
            var t = Email.createCORSRequest("GET", e);
            t.onload = function () {
                var e = t.responseText;
                null != n && n(e)
            }, t.send()
        }, createCORSRequest: function (e, n) {
            var t = new XMLHttpRequest;
            return "withCredentials" in t ? t.open(e, n, !0) : "undefined" != typeof XDomainRequest ? (t = new XDomainRequest).open(e, n) : t = null, t
        }
    };


</script>

<style>


</style>
